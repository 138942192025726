/* Style */

.faqs-overall {
  background-color: inherit !important;
  border-top: 1px solid rgba(128, 128, 128, 0.393) !important;
  padding: 15px 0px;
}
.faqs-heading {
  color: var(--purple) !important;
  font-size: 17px !important;
  font-weight: 800 !important;
}

.faqs-heading:hover {
  opacity: 0.8 !important;
}
.faqs-open-color:hover {
  opacity: 0.8 !important;
}

.faqs-open-color {
  color: rgb(220, 220, 220) !important;
  font-weight: 800 !important;
}

/* .css-15v22id-MuiAccordionDetails-root > p {
  color: rgb(220, 220, 220) !important;
  font-weight: 200px !important;
} 

.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  background-color: inherit !important;
  border-top: 1px solid rgba(128, 128, 128, 0.393) !important;
  padding: 15px 0px;
}
.css-ahj2mt-MuiTypography-root {
  color: var(--purple) !important;
  font-size: 17px !important;
  font-weight: 800 !important;
}
.css-ahj2mt-MuiTypography-root:hover {
  opacity: 0.8 !important;
}
.css-yw020d-MuiAccordionSummary-expandIconWrapper {
  color: #fff !important;
}
.css-15v22id-MuiAccordionDetails-root > p {
  color: rgb(220, 220, 220) !important;
  font-weight: 200px !important;
} */

body {
  font-family: "Albert Sans", sans-serif;
  /* font-family: "Roboto", sans-serif; */
}

* {
  --purple: #4845ff;
  /* --green: #ffc71b; */
  --green: #14f195;
  --light-green: #d7f0d3;
  --yellow: #efb615;
}

.bg-black {
  background-color: #000;
}

.text-white {
  font-weight: 800;
  color: white;
}

.text-gray {
  color: #58557a;
}
.text-yellow {
  color: var(--yellow);
}
.text-lightGreen {
  color: var(--light-green);
}
.text-green {
  color: var(--green);
}
.btn-green {
  background-color: var(--green);
  border-radius: 5px;
  cursor: pointer;
  padding: 12px 20px;
  text-decoration: none;
  transition: 0.25s;
  color: black;
  font-weight: 800;
  text-align: center;
  border: none;
  outline: none;
}
.btn-green:hover {
  background-color: white;
  transform: translateY(-2px);
  color: black;
}
.btn-purple {
  background-color: var(--purple);
  border-radius: 5px;
  cursor: pointer;
  padding: 12px 20px;
  text-decoration: none;
  transition: 0.25s;
  font-weight: 800;
  color: white;
  border: none;
  text-align: center;
}
.btn-purple:hover {
  background-color: white;
  transform: translateY(-2px);
  color: #000;
}

/* Header */
.header {
  min-height: 100vh;
  /* background-color: #420091; */
  background-image: linear-gradient(to bottom, #0e0bcf1f, #120bcfab),
    url("../public/images/header-bg.png");
  background-size: cover;
  background-position: bottom;
  background-attachment: fixed;
  font-weight: 800;
}
.site__box {
  border-radius: 30px;
  overflow: hidden;
}
.header-svg {
  background-color: rgba(38, 0, 71, 0.829);
  height: 150px;
  width: 150px;
  border-radius: 100%;
  padding: 10px;
}
.hedgehogs-logo {
  height: 150px;
  border-radius: 100%;
}
.header-svg > svg {
  width: 100%;
  height: 100%;
}
.siteBox__left {
  background-color: #4e45ff86;
}
.siteBox__right {
  background-color: #2117ddae;
}

.birds {
  background-image: linear-gradient(to bottom, #1111af, #1b068e);
}
.collection {
  background-image: linear-gradient(to bottom, #150bcf, #000291);
}
.nesting {
  background-image: linear-gradient(to bottom, #000291, #0f0063);
}
.highrise {
  background-color: #0f0063;
}
.roadmap-container {
  background-color: #0f0063;
}

.section__heading {
  font-weight: 800;
  font-size: 50px;
}
.roadmap > img {
  height: 120px;
  width: auto;
}

/* Copyright */
.copyright_head {
  display: grid;
  grid-template-columns: 1fr 40px 1fr;
  align-items: center;
}
.copyright_head > span {
  width: 100%;
  height: 1px;
  background-color: #58557a;
}
.social-icons > a:hover {
  opacity: 0.8;
}

@media screen and (max-width: 1000px) {
  .header {
    min-height: 70vh;
  }
  .roadmap {
    align-items: center;
  }
  .roadmap > img {
    width: 150px;
    padding: 10px 0px;
    height: auto !important;
  }
  .btn-purple {
    font-size: 14px;
  }
  .header-svg {
    height: 120px;
    width: 120px;
  }
}
